import React from "react";
import styled from "styled-components";

import SEO from "../components/seo";
import Header from "../components/header";
import Footer from "../components/footer";

const About = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`

const AboutMain = styled.main`
  z-index: 100;
  flex-grow: 1;
  position: relative;
  padding: 0 4vw;
  padding-bottom: 4vw;
  background: rgb(255, 255, 255);
`

const AboutArticle = styled.article`
  position: relative;
  z-index: 50;

  header {
    max-width: 1040px;
    text-align: center;
    margin: 0px auto;
    padding: 6vw 3vw 3vw;

    @media(max-width: 500px) {
      padding: 14vw 3vw 10vw;
    }

    h1 {
      font-size: 3rem;
      font-weight: 700;
      margin: 0px;

      @media(max-width: 500px) {
        font-size: 1.5rem;
      }
    }
  }

  .post-full-content {
    padding-top: 0px;
  }

  section {
    position: relative;
    min-height: 230px;
    font-family: Georgia, serif;
    font-size: 1.2rem;
    line-height: 1.6em;
    margin: 0px auto;
    padding: 70px 100px 0px;
    background: rgb(255, 255, 255);

    @media(max-width: 1170px) {
      padding: 5vw 7vw 0px;
    }

    @media(max-width: 800px) {
      font-size: 1rem;
    }

    @media(max-width: 500px) {
      padding: 0px;
    }

    p {
      margin: 0px 0px 1.5em;
    }
  }
`

const AboutPage = () => {
  return (
    <About>
      <SEO title="About" />
      <Header small />
      <AboutMain>
        <AboutArticle>
          <header>
            <h1>About</h1>
          </header>
          <section className="post-full-content">
            <p>I’m a Computer Scientist and Software Engineer and I’ve been interested in Technology ever since I was small.  To start with that meant taking things apart and inevitably not being able to put them back together, but fortunately for me the world invented software and that is what my passion has been ever since I was a teenager.</p>
            <p>My work has taken me from University (@ Durham in the U.K.) to London and then to the other side of the world where I now live in Melbourne with my wife and children.  My career has always been technology and software focused, spread across the design and build of communication networks through to the IT systems that support those networks.</p>
            <p>This blog is a collection of articles on technology, mostly the things I do in my spare time.</p>
            <p>If you'd like to know more about me and my work, you can look at these other pages:</p>
            <ul>
              <li><a href="https://www.linkedin.com/in/richardjk/" target="_blank" rel="noopener noreferrer">LinkedIn</a></li>
              <li><a href="https://github.com/richardjkendall" target="_blank" rel="noopener noreferrer">GitHub</a></li>
              <li><a href="https://hub.docker.com/" target="_blank" rel="noopener noreferrer">Docker Hub</a></li>
            </ul>
            <p>Get in touch with me <a href="/contact/">here</a>.</p>
            <p>-- Richard, Feb 2021</p>
          </section>
        </AboutArticle>
      </AboutMain>
      <Footer/>
    </About>
  );
};

export default AboutPage;
